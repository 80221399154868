import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Div = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  
  > * + * {
    padding-left: 12px;
  }
  
  > input {
    width: 16px;
    max-width: 16px;
    min-width: 16px;
    cursor: pointer;
    margin: 0;
  } 
  
  > div {
    user-select: none;
    display: flex;
    align-items: center;
  }
  
  &.disabled > div, &.disabled svg {
    color: #bcbcbc;
  }
  
  svg {
    color: #868686;
  }
`;

const FacetSelection = ({ children, onChange, checked, disabled, radio, ...props }) => {
  const onClick = () => {
    if (!disabled && onChange && typeof onChange === 'function') onChange();
  }

  return (
    <Div onClick={onClick} className={disabled ? 'disabled' : ''}{...props}>
      <input type={radio ? 'radio' : 'checkbox'} checked={checked} disabled={disabled} onChange={onClick}/>
      <div>{children}</div>
    </Div>
  )
}

FacetSelection.propTypes = {
  /** True -> checked, false -> unchecked */
  checked: PropTypes.bool,
  /** True -> disabled, false -> enabled */
  disabled: PropTypes.bool
}

export default FacetSelection;