import React from 'react'
import styled from 'styled-components'
import references from './references-config.json'
import Seo from '../../components/SEO'
import ContainerWrapper from '../../components/ContainerWrapper'
import { Col, Row } from 'react-grid-system'
import { Archive, BookOpen, ChevronDown, ChevronRight, FileText, Lock } from 'react-feather'
import { replaceVersionInUriTemplate } from '../../utils/links'
import ReleaseContext from '../../contexts/release-context'
import clsx from 'clsx'
import { navigate } from 'gatsby-link'
import FacetSelection from "../../components/FacetSelection";
import useConfig from "../../hooks/use-config";

const Style = styled.div`
  h2 {
    margin-top: 48px;
  }

  .item {

    padding-bottom: 1.4rem;

    &.linked {
      cursor: pointer
    }

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      border-radius: 8px;
      border: solid 1px #f5f5f5;
      box-shadow: 0 0.7px 2.2px rgb(0 0 0 / 1%), 0 1.6px 5.3px rgb(0 0 0 / 1%), 0 3px 10px rgb(0 0 0 / 2%), 0 5.4px 17.9px rgb(0 0 0 / 2%), 0 10px 33.4px rgb(0 0 0 / 2%), 0 24px 80px rgb(0 0 0 / 3%);
      padding: 18px 24px;
      -webkit-transition: box-shadow .3s ease-in-out;
      transition: box-shadow .3s ease-in-out;

      &:hover {
        box-shadow: 0 0.7px 2.2px rgb(0 0 0 / 2%), 0 1.6px 5.3px rgb(0 0 0 / 3%), 0 3px 10px rgb(0 0 0 / 4%), 0 5.4px 17.9px rgb(0 0 0 / 4%), 0 10px 33.4px rgb(0 0 0 / 5%), 0 24px 80px rgb(0 0 0 / 7%);
      }

      .private {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: .8rem 0;
        font-size: .8rem;
        border: solid 2px #ED8703;
        border-radius: 4px;
        padding: 6px 9px;

        svg {
          width: 16px;
          height: 16px;
          margin-right: .5rem;
        }
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .8rem;

      svg {
        margin-right: .5rem;
      }
    }

    ul {
      list-style: none;
      margin: 0;

      li, li a {
        margin: 0;
      }
    }
  }

  .row + .row {
    margin-top: 48px;
  }

  button {
    display: flex;
    align-items: center;
    background-color: #672779;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    box-shadow: none;
    cursor: pointer;
    padding: 6px 12px;
    margin-bottom: 18px;
  }

  .version {
    label {
      margin-right: 1rem;
    }

    select {
      padding: 3px 0;
      border: none;
      border-bottom: solid 1px #666;
      margin-bottom: 24px;
      background-color: white;
    }

    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: .8rem;

      svg {
        margin-right: .5rem;
      }
    }
  }

  .filter {
    h3 {
      text-transform: uppercase;
      padding-bottom: 12px;
      border-bottom: solid 1px #bcbcbc;
      margin: 48px 0 0 0;
      font-weight: 500;
    }
    h4 {
      margin: 24px 0 6px 0;
      border-bottom: none;
      font-weight: 500;
      text-transform: uppercase;
      padding-bottom: 12px;
    }
    .all-none {
      margin-top: 24px;
      font-size: smaller;
      button {
        display: inline;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        text-decoration: underline;
        color: #3f3f3f;
      }
    }
  }

  .empty-state {
    margin-top: 64px;

    a, a:active, a:visited {
      text-decoration: underline;
    }
    button {
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin: 18px 0 0 0;
      padding: 0;
      font-size: 0.9rem;
      color: #3f3f3f;
    }
  }
`

const getAllFilters = () => {
    let results = [];
    Object.entries(references.filters).forEach(
          ([keyfromfilter, value]) =>
              value.values.forEach(
                  (entry, index) => {
                   results.push(keyfromfilter + "." + entry)
                  }
              )
      )
  return results;
}


const getCategoryValue = (value) => {
  return value.split(".")[1]
}


/**
 * Calculate if the categories of the metadata of an item have at least one of the entries set by the active filters.
 * @param entry The entry which should be checked
 * @param activeFilters The current active filters
 * @returns {boolean} The item should be shown (true) or not (false)
 */
const checkMetadata = (entry, activeFilters) => {
  let onlyCategories = activeFilters.map((entry, index) => entry.split(".")[0] )
  let uniq = [...new Set(onlyCategories)];
  let noDuplicatesLength = uniq.length
  var counter = 0
  Object.keys(entry.categories).forEach((item, index) => {
    if (entry.categories[item].some(r => activeFilters.includes(item + "." + r)))
      counter++
  })
  return counter === noDuplicatesLength
}

const entriesForCategory = (activeFilters, categoryValue) => {

  //if no filter is set, all items which match the document_type value can be shown
  if (activeFilters.length === 0) {
    return true
  }
  var activeFilterMap = new Map();

  activeFilters.forEach((name, index) => {
    let filterArray = name.split(".")
    if (activeFilterMap.has(filterArray[0]) && !activeFilterMap.get(filterArray[0]).includes(filterArray[1])) {
      activeFilterMap.set(filterArray[0], [...activeFilterMap.get(filterArray[0]), filterArray[1]])
    } else if (activeFilterMap.has(filterArray[0])) {
      activeFilterMap.set(filterArray[0], [...activeFilterMap.get(filterArray[0]), filterArray[1]])
    }
    else
    {
      activeFilterMap.set(filterArray[0], [filterArray[1]])
    }
  })
  console.log(("Size of ActiveFiltersMap") +  activeFilterMap.size)
  let usedCategories = activeFilterMap.size

  //Check if any document_type is selected. When yes compare with current category value.
  if (!activeFilterMap.get("document_type")?.includes(categoryValue) && activeFilterMap.get("document_type")?.length > 0) {
    return false
  }


for(const [, value] of Object.entries(references.items)) {
  let counter = 0
  //console.log("Im in Entry: " + value.title)
  for(const [key] of Object.entries(value.categories)) {
    //console.log("Im in category: " + key + " of entry "+ value.title)
    if (activeFilterMap.has(key)) {
      //console.log("Active Filter map has element: " + key)
      if (value.categories[key].some(r => activeFilterMap.get(key).includes(r)) && value.categories["document_type"].includes(categoryValue))
        counter++
    }
  }
  if (counter === usedCategories) {
    //console.log("Counter has size of " +  counter)
    return true
  }
}
return false
}

const Items = ({ title, items, majorVersion, aep, amp, domain, children }) => (
  <>
    <Row className={'row'}>
      <Col xs={12}>
        {title && <h3>{title}</h3>}
        {children}
      </Col>
      {items.map((manual, index) => {
        // Do not render if min / max aep does not match
        if (manual.min_aep !== undefined && aep < manual.min_aep) {
          return ''
        }
        if (manual.max_aep !== undefined && aep > manual.max_aep) {
          return ''
        }
        return (
          <Col
            key={index}
            lg={4} md={6} sm={12} xs={12}
            className={clsx('item', { linked: manual.online || manual.pdf })}
            onClick={() => navigate(replaceVersionInUriTemplate(majorVersion, aep, amp, domain, manual.online ? manual.online : manual.pdf))}
          >
            <div>
              <div>
                <h4>{manual.title}</h4>
                {manual.private === true && (
                  <div className={'private'}>
                    <Lock /> CoreMedia Community login required.
                  </div>
                )}
                <p>{(manual.description !== '' && manual.description) || 'Lore ipsum dolor sit amet.'}</p>
              </div>
              <div>
                {manual.online !== undefined && (
                  <a
                    href={replaceVersionInUriTemplate(majorVersion, aep, amp, domain, manual.online)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <BookOpen/> Read online
                  </a>)}
                {manual.pdf !== undefined && (
                  <a
                    href={replaceVersionInUriTemplate(majorVersion, aep, amp, domain, manual.pdf)}
                    target={'_blank'}
                    rel={'noreferrer'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FileText/> PDF
                  </a>)}
                {manual.versions && (
                  <ul>
                    {manual.versions.map((version, index) => (
                      <li key={index}><a href={version.online}>
                        <BookOpen/>
                        {version.version}
                      </a></li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </Col>
        )
      })}
    </Row>
  </>
)

const VersionSelector = ({ releases, majorVersion, setMajorVersion, setReferenceVersion, aep, amp }) => {
  return releases ? (
    <div className={'version-selector'}>
      {/* eslint-disable */}
      <select value={majorVersion} onChange={(e) => setMajorVersion(e.target.value)}>
        <option value={'cmcc12'}>12</option>
        <option value={'cmcc11'}>11</option>
        <option value={'cmcc10'}>10</option>
      </select>
      &nbsp;&nbsp;&nbsp;
      <select
        id={'reference-version-selector'}
        value={`${aep}.${amp}`}
        onChange={(e) => setReferenceVersion(e.target.value)}>
        {/* eslint-enable */}
        {releases[majorVersion] && releases[majorVersion].distributions.map((release, index) => (
          <React.Fragment key={index}>
            <optgroup label={`${(!release.aep.version.endsWith('.0') && release.aep.version.includes('.')) ? 'LTS' : 'AEP'} ${release.aep.version}`}/>
            {release.amp.map((amp, i) => (
              <option key={i}
                      value={`${release.aep.version}.${amp.version}`}>
                {release.aep.version}.{amp.version}
                {(index === 0 && i === 0) && ' (Latest)'}
              </option>
            ))}
          </React.Fragment>
        ))
        }
      </select>
    </div>
  ) : ''
}

const ReferenceMaterials = ({ pageContext }) => {

  const config = useConfig();

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const releases = React.useContext(ReleaseContext)
  const [ aep, setAep ] = React.useState()
  const [ amp, setAmp ] = React.useState()
  const [ showApi, toggleApi ] = React.useState(false)
  const [ majorVersion, setMajorVersion ] = React.useState('cmcc12')
  const [ activeFilters, setActiveFilters ] = React.useState([])

  const toggleSelection = (name) => {
    if (activeFilters.includes(name)) {
      setActiveFilters(activeFilters.filter(item => item !== name))
    } else {
      setActiveFilters([ ...activeFilters, name ])
    }
  }

  React.useEffect(() => {
    setAep(releases[majorVersion]?.distributions[ 0 ].aep.version)
    setAmp(releases[majorVersion]?.distributions[ 0 ].amp[ 0 ]?.version)

  }, [ releases, majorVersion ])

  const setReferenceVersion = (value) => {
    const split = value.split('.')
    if (split.length !== 2) {
      setAep(split[ 0 ]+'.'+split[ 1 ])
      setAmp(split[ 2 ])
    } else {
      setAep(split[ 0 ])
      setAmp(split[ 1 ])
    }

  }

  const versionSelector = <VersionSelector releases={releases} majorVersion={majorVersion} setMajorVersion={setMajorVersion}
                                           amp={amp} aep={aep}
                                           setReferenceVersion={setReferenceVersion}/>

  return (
    <ContainerWrapper crumbs={crumbs} fluid={true}>
      <Seo
        title={`Reference Materials`}
        description={'Detailed information about CoreMedia Content Cloud. Go down the rabbit hole.'}
      />
      <Style>
        <Row>
          <Col xs={12}>
            <h1>Reference Materials</h1>
            <p>
              Detailed information about CoreMedia Content Cloud. Go down the rabbit hole.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={0} md={4} className={"filter"} style={{maxWidth: '100%', width: '100%'}}>
            <h3>Version</h3>
            <div className={'version'}>
              <p></p>
              {versionSelector}
              <a href={replaceVersionInUriTemplate(majorVersion, aep, amp, config.site_url, references.urls.manuals.download)}>
                <Archive/>
                Download all manuals from this version as ZIP
              </a>
              <br />
              <p>
                Select the distribution version for resources that are released with Agile Enhancement Packs (AEP) or
                Agile Maintenance Packs (AMP).
              </p>
            </div>
            <h3>Filter</h3>
            <div className={'all-none'}>
              Select&nbsp;
              <button onClick={() => setActiveFilters(getAllFilters())}>All</button>&nbsp;
              <button onClick={() => setActiveFilters([])}>None</button>
            </div>
            {Object.keys(references.filters).map( function (name, index) {
              return (
                  <div key={name}>
                  <h4>{references.filters[name].label}</h4>
                  {references.filters[name].values.map(function (value, index) {
                    return (
                    <FacetSelection
                        key={index}
                        onChange={() => toggleSelection(name + "." + value)}
                        checked={activeFilters.includes(name + "." + value)}
                    >
                      {value}
                    </FacetSelection>
                    );
                  }
              )
            }
                  </div>)
            }
            )}



          </Col>
          <Col xs={12} md={8}>
            {/*    {activeFilters.length === 0 && (
              <div className={'empty-state'}>
                <p>
                  There's nothing we can display because all filters are deactivated.
                </p>
                <div>
                  <button onClick={() => setActiveFilters(getAllFilters())}>Show All</button>
                </div>
              </div>
            )} */}

            {references.filters.document_type.values.map((name, index) => {
              //console.log("The entriesForCategor method returns: " + entriesForCategory(activeFilters, name) + "for category:" + name )
              if (!entriesForCategory(activeFilters, name)) return ''
              return (
                  <React.Fragment key={index}>
                    <h2>{name}</h2>
                    {references.filters.document_type.image && (
                        <>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button onClick={() => toggleApi(v => !v)}>API Overview {showApi ? <ChevronDown /> : <ChevronRight />}</button>
                          </div>
                          {showApi && (
                              <img
                                  src={replaceVersionInUriTemplate(majorVersion, aep, amp, config.site_url, references.filters.document_type.image)}
                                  alt={'API Overview'}
                              />
                          )}
                        </>
                    )}
                    <Items
                        items={references.items.filter(item => (item.categories.document_type.includes(getCategoryValue("document_type." + name)) && checkMetadata(item, activeFilters)))}
                        majorVersion={majorVersion}
                        aep={aep}
                        amp={amp}
                        domain={config.site_url}
                    />
                  </React.Fragment>

              )
            })}

          </Col>
        </Row>
      </Style>
    </ContainerWrapper>
  )
}

export default ReferenceMaterials
